import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import "./OffresAbonnement.css"
import Illustration1 from "./Illustration1.svg"
import Illustration2 from "./Illustration2.svg"
import Illustration3 from "./Illustration3.svg"
// import Illustration4 from "./Illustration4.svg"
// import Illustration5 from "./Illustration5.svg"
import StValentinIcon from "./valentine-svgrepo-com.svg"

export default function OffresAbonnement() {
    useEffect(() => {
        document.title = "Offres & Abonnement - Azoya SPA Vaux";
    }, [])

    return (
        <div className='OffresAbonnement'>
            <div className="slide" id="slide1">
                <div className="image-container">
                    <h1>Nous vous proposons des concepts <em>adaptés</em> à vos besoins</h1>
                    <img src={Illustration1} alt="Illustration"/>
                </div>
            </div>
            <div className="slide" id="slide5">
                <img src={StValentinIcon} alt="Illustration promotion"/>
                <div className="text-container">
                    <h1>Offre spéciale <em>Saint-Valentin</em> en duo !</h1>
                    <p>
                        Pour l'occasion, votre institut sera ouvert le dimanche 16 février 2025 de 10h à 18h.
                    </p>
                    <table border="1">
                        <tr>
                            <th>Service</th>
                            <th>Tarif Original</th>
                            <th>Tarif Réduit</th>
                        </tr>
                        <tr>
                            <td>Bulle d'Ô + soin relaxant 60 min</td>
                            <td><s>212 €</s></td>
                            <td>190 €</td>
                        </tr>
                        <tr>
                            <td>Bulle d'Ô + soin balinais 60 min</td>
                            <td><s>222 €</s></td>
                            <td>200 €</td>
                        </tr>
                        <tr>
                            <td>Bulle d'Ô + soin lomi lomi 60 min</td>
                            <td><s>242 €</s></td>
                            <td>215 €</td>
                        </tr>
                    </table>
                    <ul>
                        <li>*Valable du 14 au 17 février inclus</li>
                        <li>*Réservation avec acompte obligatoire (mini 50%)</li>
                        <li>*Hors carte cadeau</li>
                    </ul>
                </div>
            </div>
            {/* <div className="slide" id="slide4">
        <div className="text-container">
          <h1>Offre de <em>lancement</em> !</h1>
          <p>
            Profitez de <em>-20%</em> sur votre cure de photo-dépilation ou de photo-rajeunissement du <em>09/01/2023</em> au <em>11/02/2023</em>.
            Un rendez-vous bilan est nécéssaire pour tout vous expliquer. N'hésitez pas, il est gratuit.
          </p>
        </div>
        <img src={Illustration4} alt="Illustration" />
      </div> */}

            <div className="slide" id="slide2">
                <img src={Illustration2} alt="Illustration"/>
                <div className="text-container">
                    <h1>Les pass abonnements de <em>6</em> ou <em>12</em> mois</h1>
                    <p>Le pass est individuel pour une durée de six mois (50,00€) ou un an (80,00€). Il offre 50% de
                        remise sur les épilations classiques et des réductions sur d'autres prestations. Le pass doit
                        être présenté à chaque rendez-vous, sans la carte nous ne pourrons pas appliquer les tarifs
                        préférentiels.</p>
                </div>
            </div>
            <div className="slide" id="slide3">
                <div className="text-container">
                    <h1>Les cartes cadeaux pour <em>faire plaisir</em></h1>
                    <p>
                        Faites plaisir à vos proches, offrez des moments de détente, offrez des cartes cadeaux. Vous
                        pouvez offrir des cartes cadeaux pour une prestation ou pour un montant.<br/>
                        Cliquez <Link to='/offrir'>ici</Link> ou sur le cadeau pour en commander.
                    </p>
                </div>
                <Link to='/offrir'><img src={Illustration3} alt="Illustration"/></Link>
            </div>
        </div>
    )
}